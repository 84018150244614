.containerSocial{
    position: absolute;
    width: 142px;
    height: 34px;
    top: 100px;
    right: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 1;
}
.item{
    height: 34px;
    width: 34px;
}
@media (min-width: 1925px){
  .containerSocial{
      right: 30%;
  }
}
@media (max-width: 450px){
    .containerSocial{
        width: 320px;
        height: 34px;
        top: 110px;
        right: 0;
        left: 0;
        margin: 0 auto 0 auto;
    }
}