.footerCard {
    border-radius: 10px;
    box-shadow: 0 0 20px -1px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(25px);
    width: 310px;
    height: 376px;
    margin: 15px;
    background: url("../../../Asseets/Footer/cardBackground.png"), lightgray 0% 0% / 100px 100px repeat, linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(217, 217, 217, 0.04) 100%);
    background-position: center;
}

.containerCards {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    height: 70%;
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
}

.footerCardVk {
    margin: 20px 18px 20px 18px;
    border-radius: 10px;
    width: 280px;
    height: 178px;
    background: url("../../../Asseets/Footer/vk.jpg") center;
}


.footerCardYoutube {
    margin: 20px 18px 20px 18px;
    border-radius: 10px;
    width: 280px;
    height: 178px;
    background: url("../../../Asseets/Footer/youtube.jpg") center;
}


.footerCardDiscord {
    margin: 20px 18px 20px 18px;
    border-radius: 10px;
    width: 280px;
    height: 178px;
    background: url("../../../Asseets/Footer/discord.jpg") center;
}


.footerCardText {
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 40.32px */
    width: 268px;
    margin: 20px auto 20px auto
}

.footerCardButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-family: Roboto, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    width: 138px;
    height: 46px;
    border: 1px solid #D6E9FF;
    border-radius: 7px;
    backdrop-filter: blur(6.5333333015441895px);
    margin: 20px 86px 40px 86px;
}

.footerCardButton:hover {
    background: #6B68F1;
    border: 0;
}
@media (max-width: 760px) and (min-width: 451px){
    .containerCards {
        flex-wrap: wrap;
        height: 700px;
        width: 80%;
        left: 0;
        margin: 0 0 0 25px;
    }
    .footerCard{
        width: 280px;
        height: 300px;
    }
    .footerCardVk{
        width: 240px;
        height: 80px;
        background-size: cover;
        background-position: 0 50%;
    }
    .footerCardYoutube{
        width: 240px;
        height: 80px;
        background-size: cover;
        background-position: 0 50%;
    }
    .footerCardDiscord{
        width: 240px;
        height: 80px;
        background-size: cover;
        background-position: 0 45%;
    }
    .footerCardText{
        font-size: 20px;
        width: 88%;
        height: 82px;
    }
    .footerCardButton{
        margin: 0 40px 0 40px;
    }
}
