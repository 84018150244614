.containerButtonBetaTest {
    position: relative;
    left: 70%;
    top: 60vh;
    height: 78px;
    width: 239px;
    user-select: none;
}
.containerButtonBetaTest:hover{
    cursor: pointer;
}

.buttonBetaTest {
    width: 239px;
    height: 78px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #6B68F1;
    z-index: 1;
    position: absolute;
    border: none;
    color: #FFF;
    font-family: Roboto, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 40px */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-decoration: none;

}

@media (min-width: 1925px){
    .containerButtonBetaTest{
        left: 75%;
    }
}
@media (max-width: 450px){
    .containerButtonBetaTest {
        position: relative;
        top: 450px;
        left: 0;
        height: 50px;
        width: 100%;
    }
    .buttonBetaTest{
        margin: 0 auto 0 auto;
        font-size: 16px;
        width: 150px;
        height: 50px;
        left: 0;
        right: 0;
    }

    .buttonBetaTestEffect {
        width: 165px;
        height: 60px;
        left: 0;
        right: 0;
        margin: 0 auto 0 auto;
    }
}

@media (max-width: 760px) and (min-width: 451px){
    .containerButtonBetaTest {
        position: relative;
        top: 500px;
        left: 0;
        height: 50px;
        width: 100%;
    }
    .buttonBetaTest{
        margin: 0 auto 0 auto;
        font-size: 16px;
        width: 150px;
        height: 50px;
        left: 0;
        right: 0;
    }

    .buttonBetaTestEffect {
        width: 165px;
        height: 60px;
        left: 0;
        right: 0;
        margin: 0 auto 0 auto;
    }
}