.containerDown {
    width: 105px;
    height: 31px;
    position: relative;
    top: 75vh;
    left: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.downItem {
    padding: 0 0 0 10px;
}

.downText {
    color: #FFF;
    font-family: Roboto, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 30px */
    text-decoration: none;
}

@media (max-width: 450px){
    .containerDown {
        top: 400px;
        width: 70px;
        height: 25px;
        left: 0;
    }
    .downText{
        font-size: 12px;
        margin-top: 15px;
    }
    .downItem {
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 450px) {
    .containerDown {
        display: none;
    }
}
@media (max-width: 760px) and (min-width: 451px){
    .containerDown {
        top: 490px;
        width: 70px;
        height: 25px;
        left: 0;
    }
    .downText{
        font-size: 12px;
        margin-top: 15px;
    }
    .downItem {
        width: 30px;
        height: 30px;
    }
}