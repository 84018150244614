.notFound {
    width: 100vw;
    height: 100vh;
    background: url("../Asseets/Other/background3.jpg");
    background-size: cover;
    display: flex;
}

.center {
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: column;
    margin: auto auto auto auto;
}

.logo {
    width: 120px;
    height: 110px;
    display: block;
    background: url("../Asseets/Other/logo.svg");
    background-size: cover;
    margin: auto;
}

.text {
    color: #FFF;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin: auto;
    text-align: center;
    text-shadow:
            1px 1px 0 rgba(63, 63, 63, 0.58),
            -1px 1px 0 rgba(63, 63, 63, 0.58),
            -1px -1px 0 rgba(63, 63, 63, 0.58),
            1px -1px 0 rgba(63, 63, 63, 0.58);
}