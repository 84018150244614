.secondBlock{
    background-image: url("../../Asseets/SecondBlock/background.jpg");
    background-size: cover;
    width: 100%;
    height: 1080px;
    margin: 0;
}
@media (max-width: 450px){
    .secondBlock{
        height: 600px;
    }
}
@media (max-width: 760px) and (min-width: 451px){
    .secondBlock {
        height: 652px;
    }
}
