.slide {
    display: block;
    height: auto;
    max-width: 90%;
    width: auto;
}

.slideWithoutSlider {
    display: block;
    height: auto;
    max-width: 90%;
    width: auto;
    margin: 15px auto 0 auto;
}

.news2Container {
    margin: 20px auto 20px auto;
    width: 80%;
    height: auto;
    max-height: 1080px;
    display: flex;
    flex-direction: row;
    justify-content: center
}

.slideButton {
    width: 60px;
    height: 60px;
    display: block;
    margin: auto 5px auto 5px;
    text-align: center;
    font-size: 48px;
    color: white;
}
.slideButton:hover{
    cursor: pointer;
}

details {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 0.5em 0.5em 0;
    width: auto;
    margin: 15px auto auto auto;
}

summary {
    width: auto;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: -0.5em -0.5em 0;
    padding: 0.5em;
}

details[open] {
    padding: 0.5em;
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5em;
}
