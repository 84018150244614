.newsPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    background: url("../Asseets/Footer/background.png");
    background-size: cover;
    overflow-y: auto;
}

.newsPageContainer{
    width: 80%;
    height: auto;
    margin: 20px auto auto;
    display: flex;
    flex-direction: column;
}

.newsPageHeader{
    width: 100%;
    height: auto;
    color: #FFF;
    text-align: center;
    text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    font-family: Gotham Pro, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 172.8px */
    margin-top: 20px;
}
.newsPageText{
    color: #c5c5c5;
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 40px */
    width: 80%;
    height: auto;
    margin: 30px auto 0 auto;
    text-align: center;
}

.newsPageImage{
    width: 300px;
    height: 300px;
    background: red;
    margin: 20px auto 20px auto;
}
.word{
    color: #36b9fb;
}
.frame{
    margin: 20px auto 20px auto;
    width: 60%;
    height: 50vh;
}

.left{
    text-align: left;
}