.containerUp{
    width: 105px;
    height: 31px;
    position: relative;
    bottom: 12vh;
    left: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.upItem{
    padding: 0 0 0 10px;
}
.upText{
    color: #FFF;
    font-family: Roboto, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 30px */
    text-decoration: none;
}
@media (max-width: 760px) and (min-width: 451px){
    .containerUp{
        bottom: 0;
    }
}