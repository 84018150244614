.containerNews {
    display: block;
    width: 100%;
    height: 50%;
    position: relative;
    top: 20%;
}

.news {
    width: 722px;
    height: 310px;
    display: block;
    margin: 0 auto 0 auto;
}

.containerTextNews a:link{
    font-family: Roboto;
    font-weight: 600;
}
.containerTextNews a:visited{
    font-family: Roboto;
    font-weight: 600;
    color: #36b9fb;
}
.newsBackGround1 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News1.png");
    background-size: cover;
    display: block;
}
.newsBackGround2 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News2.png");
    background-size: cover;
    display: block;
}
.newsBackGround3 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News3.png");
    background-size: cover;
    display: block;
}
.newsBackGround4 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News4.png");
    background-size: cover;
    display: block;
}
.newsBackGround5 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News5.png");
    background-size: cover;
    display: block;
}
.newsBackGround6 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News6.png");
    background-size: cover;
    display: block;
}
.newsBackGround7 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News7.png");
    background-size: cover;
    display: block;
}
.newsBackGround8 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News8.png");
    background-size: cover;
    display: block;
}
.newsBackGround9 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News9.png");
    background-size: cover;
    display: block;
}
.newsBackGround10 {
    width: 100%;
    height: 100%;
    background-image: url("../../../Asseets/SecondBlock/News/News10.png");
    background-size: cover;
    display: block;
}
.containerTextNews {
    display: flex;
    flex-direction: column;
    width: 268px;
    float: right;
    margin: 30px 10px 0 0;
}

.newsHeader {
    color: #22242A;
    text-align: center;
    font-family: Oswald;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%; /* 57.6px */
}

.newsDescription {
    color: #22242A;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 28.8px */
    width: 270px;
}

.dots {
    margin: 10px 0 0 0;
    text-align: center;
}

.dot {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border: 2px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
.active {
    background-color: white;
    box-shadow: 0 0 10px #FFF;
}

@media (max-width: 450px){
    .newsBackGround1{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround2{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround3{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround4{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround5{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround6{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround7{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround8{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround9{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround10{
        width: 300px;
        height: 400px;
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .containerNews{
        width: 300px;
        height: 400px;
        margin: 0 auto 0 auto;
    }
    .news{
        width: 300px;
        height: 400px;
    }
    .containerTextNews {
        width: 260px;
        height: 360px;
        margin: 5px 15px 0 0
    }

    .newsHeader {
        font-size: 32px;
        text-align: center;
        color: #ffffff;
    }

    .newsDescription {
        font-size: 18px;
        text-align: center;
        margin: 0;
        color: #c4c4c4;
    }
}

@media (max-width: 760px) and (min-width: 451px){
    .containerNews{
        width: 300px;
        height: 200px;
        margin: 0 auto 0 auto;
    }
    .news{
        width: 100%;
        height: 100%;
    }
    .containerTextNews {
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .newsHeader {
        font-size: 20px;
        text-align: center;
        margin-bottom: 0;
        color: white;
        margin-top: 10px;
    }

    .newsDescription {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 15px;
        text-align: center;
        color: #c4c4c4;
    }

    .newsBackGround1{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");

    }
    .newsBackGround2{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround3{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround4{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround5{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround6{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround7{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround8{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround9{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
    .newsBackGround10{
        border-radius:15px;
        background-image: url("../../../Asseets/SecondBlock/News/NewsBlack.png");
    }
}

