.containerCharacter {
    position: absolute;
    top: 0;
    right: 0;
}

.characterImage {
    width: 1192px;
    height: 1080px;
}

@media (max-width: 450px){
    .containerCharacter {
        position: absolute;
        right: 0;
        top:39px;
        width: 320px;
        height: 560px;
        overflow: hidden;
    }
    .characterImage{
        display: block;
        width: 834px;
        height: 756px;
        object-fit: fill;
        background-size: auto;
        transform: translateX(-25%);
    }
}

@media (max-width: 760px) and (min-width: 451px){
    .containerCharacter {
        position: absolute;
        top: 92px;
        right: 0;
        width: 760px;
        height: 560px;
        overflow: hidden;
    }
    .characterImage{
        display: block;
        width: 834px;
        height: 756px;
        object-fit: fill;
        background-size: auto;
        transform: translateX(5%);
    }
}