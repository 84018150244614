.footer {
    width: 100%;
    height: 1280px;
    background: url("../../Asseets/Footer/background.png");
    background-position-y: center;
    background-size: cover;
    margin: 0;
}

@media (max-width: 450px) {
    .footer {
        display: none;
    }
}