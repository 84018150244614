.header {
    overflow: hidden;
    height: 65px;
    position: relative;
    width: 100%;
    background-image: url("../../../Asseets/FirstBlock/RunningLine/background.png");
}
.runningLine-img{
    width: 28px;
    height: 28px;
    margin: auto 18px auto 0;
}
.runningLine-text{
    color: #FFF;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110.6%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    width: 450px;
}
.run{
    margin-top:18px;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    position: absolute;
    animation: ticker 30s linear infinite;
}
.run1{
    animation-delay: -30s;
}
.run2{
    animation-delay: -25s;
}
.run3{
    animation-delay: -20s;
}
.run4{
    animation-delay: -15s;
}
.run5{
    animation-delay: -10s;
}
.run6{
    animation-delay: -5s;
}
.run7{
    animation-delay: 0s;
}
.run8{
    transform: translate(90%, 0);
    animation-delay: 5s;
}

@keyframes ticker {
    0% {
        transform: translate(90%, 0);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-90%, 0);
    }
}

@media (max-width: 450px){
    .run {
        animation: ticker 10s linear infinite;
    }
    .run1{
        animation-delay: -5s;
    }
    .run2{
        display: none;
    }
    .run3{
        display: none;
    }
    .run4{
        display: none;
    }
    .run5{
        display: none;
    }
    .run6{
        display: none;
    }
    .run7{
        display: none;
    }
    .run8{
        display: none;
    }

    @keyframes ticker {
        0% {
            transform: translate(110%, 0);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(-110%, 0);
        }
    }
}

@media (max-width: 760px) and (min-width: 451px){
    .run1{
        animation-delay: -30s;
    }
    .run2{
        animation-delay: -20s;
    }
    .run3{
        animation-delay: -10s;
    }
    .run4{
        display: none;
    }
    .run5{
        display: none;
    }
    .run6{
        display: none;
    }
    .run7{
        display: none;
    }
    .run5{
        display: none;
    }
    .run6{
        display: none;
    }
    .run7{
        display: none;
    }
    .run8{
        display: none;
    }

    @keyframes ticker {
        0% {
            transform: translate(120%, 0);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(-120%, 0);
        }
    }
}