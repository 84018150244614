.containerText {
    position: absolute;
    top: 150px;
    left: 13%;
    width: 667px;
    height: 443px;
}

.headerText {
    color: #FFF;
    text-align: center;
    text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    font-family: Gotham Pro, serif;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 172.8px */
    padding: 0 32px 30px 6px;
}

.mp5Text {
    background: linear-gradient(199deg, #0EF 0%, #7793EF 48.96%, #F449F4 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Gotham Pro, serif;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
}

.descriptionText {
    color: #FFF;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 40px */
    width: 667px;
    padding: 0 0 0 32px;
}
.emptyLine{
    height: 32px;
}

@media (min-width: 1925px){
    .containerText {
        left: 40%;
    }
}
@media (max-width: 450px){
    .containerText {
        width: 350px;
        height: 80px;
        top: 200px;
        left: 0;
        right: 0;
        margin: 0 auto 0 auto;
    }
    .headerText {
        font-size: 50px;
        padding: 0;
    }
    .mp5Text {
        font-size: 55px;
    }
    .descriptionText{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 40px */
        width: 80%;
        padding: 0;
        margin: 20px auto 0 auto;
        text-align: center;
    }
}
@media (max-width: 760px) and (min-width: 451px){
    .containerText {
        width: 400px;
        height: 60px;
        top: 200px;
        left: 5%;
    }
    .headerText {
        font-size: 70px;
        padding: 0;
    }
    .mp5Text {
        font-size: 70px;
    }
    .descriptionText{
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 40px */
        width: 400px;
        padding: 0;
        margin: 20px auto 0 auto;
        text-align: center;
    }
}